:root {
  --main-bg-color: rgb(227, 25, 69);
}

/*### 1-  Genaral CSS ###*/
body { background-color: #ffffff !important; } /* Or: f4f4f4 - #f0f2f5 - f9fafb*/
::-webkit-scrollbar { width: 7px  !important; height: 2px !important; }
::-webkit-scrollbar-track { background: transparent; }
::-webkit-scrollbar-thumb { background: #a9acb0; border-radius: 5px; }
::-webkit-scrollbar-thumb:hover {  background: #595a5c; border-radius: 5px; }
a { text-decoration: none !important; }
@font-face {
  font-family: "Droid";
  src: url("../../public/Fonts/Droid.ttf");
  font-size: 8px !important;
}
@font-face {
  font-family: "Hs-b";
  src: url("../../public/Fonts/HSB.otf");
  font-size: 8px !important;
}
@font-face {
  font-family: "Hs-t";
  src: url("../../public/Fonts/HST.otf");
  font-size: 10px !important;
}
@font-face {
  font-family: "Hs-n";
  src: url("../../public/Fonts/HS1.otf");
  font-size: 10px !important;
}
.font-hs-d { font-family: "Hs-b" , "Droid" !important; }
.font-hs-t { font-family: "Hs-t" , "Droid" !important; }
.font-hs-n { font-family: "Hs-n" , "Droid" !important; }
body,
h1,
h2,
h3,
h4,
h5,
input,
Select,
option,
button,
Table,
a,
TableCell,
.Toastify__toast,
.font-droid {
  font-family: "Droid" , tahoma !important;
}


.font-droid {
  font-family: "Droid" !important;
}

/* body,h1,h2,h3,h4,h5,h6,.font-droid,Input,TextArea, .Toastify__toast { font-family: "Droid" , tahoma !important; } */
.font-hs-d { font-family: "Hs-b" , "Droid" !important; }
.font-hs-t { font-family: "Hs-t" , "Droid" !important; }
.font-hs-n { font-family: "Hs-n" , "Droid" !important; }

/*LogIn*/
.login-caontainer { padding-top: 40px; }
.w-login-input{ width: 60%; }
@media only screen and (max-width: 768px) { .w-login-input{ width: 100%; } }

/*navbar images*/
.navBar-img { border-radius: 20px !important; }

/*bootsrap icons*/
.bi-sm { font-size: 20px; }
.bi-lg { font-size: 50px; }
.bi-md { font-size: 30px; }
.bi-xlg { font-size: 100px; }
.bi-upl{ font-size: 37px; }
/*boostrap modification*/
btn:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 #ddd;
}

.btn:hover, .btn:focus, .btn:active { box-shadow: none !important; }
.border-d-sm { border-radius: 10px !important; }
.border-d-md {  border-radius: 30px !important; }
.border-d-lg { border-radius: 50px !important; }

/*system*/
.navshadow {
  -webkit-box-shadow: 1px 1px 6px 1px gray !important;
  -moz-box-shadow: 1px 1px 6px 1px gray;
  box-shadow: 1px 1px 6px 1px gray;
}
.abyedh-list { color:#798599 !important;  font-size: 15px; font-weight: bold;}
.abyedh-list-a-s { border-bottom: 2px solid var(--main-bg-color) !important; border-radius: 3px 3px 0px 0px !important; color: var(--main-bg-color) !important; font-weight: bold;}
/* .abyedh-list-a { border-bottom: 2px solid var(--main-bg-color) !important; background-color: #dedfdf !important; border-radius: 3px 3px 0px 0px !important;  color: rgb(41, 41, 41) !important; font-size: 15px;} */
.abyedh-list-a {background-color: #cce0fc !important; border-radius: 9px 9px 9px 9px !important;  color: var(--main-bg-color) !important; font-size: 15px; font-weight: bold;}
.btn-system { border-radius: 5px !important; margin-right: 10px; }
.start-page-loader { z-index: 1101; position: fixed !important; top: 50% !important; left: 50% !important; }
.btn-imprimer{background-color: #08a87d !important; color: white !important;}
.btn-regler{background-color: #b3126d !important; color: white !important;}


/*bootstrap nav */
.tab-content { border-top: 0px rgb(211, 211, 211) solid; }
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active { background: #e0eeff !important; border-bottom: 3px solid rgb(92, 110, 214) !important; border-top: 0 !important; border-left: 0 !important; border-right: 0 !important; color: rgb(92, 110, 214) !important; }


/*resonsive bar*/
.responsive-bar { white-space: nowrap !important; overflow-x: auto !important; overflow-y: none !important; }
.wrapped{ display: flex; flex-direction: row; flex-wrap: wrap; }
.active.item { border-color: var(--main-bg-color) !important; }
.rounded-system{ border-radius: 20px; }
.form-check-input{ width: 35px !important; height: 17px !important;}
.form-check-input:checked { background-color: var(--main-bg-color) !important; border-color: pink; }
.sub-sys-round{ border-radius: 15px !important; }
.main-big-card{ background-image: linear-gradient(to right top, var(--main-bg-color),  #ffffff); border: 0px solid; }
.catalogue-big-card{ background-image: linear-gradient(to bottom top, var(--main-bg-color) ,  #ffffff); border: 0px solid; }
.w-100-seach-input input, .w-100-seach-input{ width:100% !important;}
.icon-nav-div{ background-color: white; color: var(--main-bg-color); margin-right: 5px; text-align: center; padding: 4px; padding-left: 6px; }
.outer-for-subnav { white-space:nowrap !important; overflow-x: scroll !important; }
.rounded-message-s{ border-top-left-radius: 20px; border-bottom-left-radius: 20px; border-top-right-radius: 20px; border-bottom-right-radius: 20px; width: auto !important; text-align: justify; }
.rounded-message-r{ border-top-right-radius: 20px; border-bottom-right-radius: 20px; border-top-left-radius: 20px; border-bottom-left-radius: 20px; width: auto !important; text-align: justify; }
.elevation { box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important; transition: all 0.3s cubic-bezier(.25,.8,.25,1) !important; border-radius: 3px  !important; }
.border-div{ border-radius: 10px !important; }

/*map-height*/
.map-height { height: 250px !important; }
.map-height-lg { height: 440px !important;}
.map-height-md { height: 900px !important; }
.map-height-cmd { height: 650px !important; }
.map-height-sm{height: 50px;}
.bg-system-btn{ background-color: var(--main-bg-color) !important; color: white !important; }
.w-cal-in{ width: 26px !important; text-align: center !important;  background-color: #06c436 !important; }
.w-cal-out{ width: 26px !important; text-align: center !important; background-color: #c40636 !important;}
.fc-day-spancmd  { background-color: red !important;}
.system-color{ color: var(--main-bg-color) !important; }

/* crad container */
.upper{ height: 100px;}
.upper img{ width: 100%; border-top-left-radius: 10px; border-top-right-radius: 10px; }
.img-card-container{ position: relative;}
.mcbg{ height: 90px !important; width: 100% !important; margin-top:2px; border-radius: 10px 10px 0 0 ; }
.card-container{ position: absolute; top:-50px; left: 38%; height: 90px; width: 90px; border:5px solid #fff; border-radius: 50%; padding: 0px; }

.progress-bar-loading {
  height: 2.5px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  border-radius: 50% 50% 0 0  !important;
}

.progress-bar-loading-value {
  width: 100%;
  height: 100%;
  background-color: rgb(5, 114, 206);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.bg-blur-navbar {
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.top-catalogue{border-radius: 0px 50px 50px 0px;}
.bottom-catalogue{border-radius: 40px 0px 0px 40px;}
.w-input-catalogue{width: 60% !important;}
.bg-about{
  /* background-image: url("https://images.pexels.com/photos/7130560/pexels-photo-7130560.jpeg"); */
  background-image: url("https://images.unsplash.com/photo-1557683311-eac922347aa1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=829&q=80");
  /* background-image:  linear-gradient(to bottom top, var(--main-bg-color) ,  #ffffff); */
  background-repeat:no-repeat;
  background-size: 100% 1300px !important; /* 1265 */
}
@media print { .breack-page-here { page-break-before: always;  } }

/*
.dark {
  background-color: #333 !important;
  color: #fff !important;
}
.card, .fixed-top { background-color: #616479 !important;}
*/
.gridjs-wrapper{border: none !important; box-shadow: none !important;}
.gridjs-table{border: none !important; box-shadow: none !important;}
.gridjs-footer {border: none !important; box-shadow: none !important; padding-top: 10px !important;}
.gridjs-tr:hover {background-color: #1559b3 !important;}
.data-link-modal{cursor: pointer !important;}
.blur-bckg{background-color: rgba(255, 255, 255, 0.3) ; backdrop-filter:blur(30px) !important;}
.bg-hover-card:hover{ background-color: #cce0fc !important;}
.Toastify__toast {
  border-radius: 12px !important;
  padding: 10px !important;
  /* box-shadow: 5px 10px rgb(238, 236, 236); */
}
.toast-bg-card{background-color: #ebedeb !important;}

.notification {
  position: relative;
  display: inline-block;
}

.notification .badged {
  position: absolute;
  bottom: 0px;
  right: -5px;
  padding: 5px ;
  border-radius: 50%;
  background-color: #009166;
  border: 3px solid white;
}
.notification .badged-inside {
  position: absolute;
  bottom: -2x;
  left: -20px;
  padding: 5px ;
  border-radius: 50%;
  background-color: #009166;
  border: 3px solid white;
}
.no-menu-tabs .ui.menu { display: none !important; }
.yes-menu-tabs .ui.menu { display: flex !important; }
.btn-cursor {cursor: pointer !important;}
.gridjs table td {
  white-space: nowrap !important;
  overflow: hidden !important;
  /* text-overflow: ellipsis !important;  OUsed Old One  */
  text-overflow: clip !important; /* Optional: Add ellipsis for long content */
}
@media print { .breack-page-here { page-break-before: always;  } }